import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import media from 'styles/media'

import { scrollToNextSibling } from 'utils/scrollToSection'

import discoverIcon from 'assets/icons/discover.svg'

import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'

type MainHeaderProps = {
  image: {
    src: IGatsbyImageData
    alt: string
  }
  title: string
  buttonText?: string
  buttonColor?: string
  noDiscoverButton?: boolean
  noActionButton?: boolean
  hasPeopleImg?: boolean
  noRadialGradient?: boolean
}

type StyledActionButtonProps = {
  buttonColor: string
  noActionButton?: boolean
}

const StyledWrapper = styled.div<{ hasPeopleImg?: boolean }>`
  width: 100%;
  height: 100vh;
  position: relative;

  .people-img {
    display: none;
  }

  ${({ hasPeopleImg }) =>
    hasPeopleImg &&
    css`
      ${media.lg.min} {
        .people-img {
          display: block;
        }
      }
    `}
`

const StyledHeroImage = styled(GatsbyImage)<{ noRadialGradient?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100vh;

  ${({ noRadialGradient }) =>
    !noRadialGradient &&
    css`
      mask-image: radial-gradient(circle, white 70%, rgba(0, 0, 0, 0.3) 70%);

      ${media.lg.min} {
        mask-image: radial-gradient(
          circle,
          white 50%,
          rgba(255, 255, 255, 0.3) 50%
        );
      }

      ${media.xl.min} {
        mask-image: radial-gradient(circle, black 35%, rgba(0, 0, 0, 0.3) 35%);
      }
    `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    background: linear-gradient(
      to top,
      white,
      rgba(255, 255, 255, 0.45),
      white
    );
  }

  & > img {
    object-fit: cover;
    object-position: 0% 0%;
  }
`

const StyledMainHeaderContent = styled.div`
  height: 100%;
  padding: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledTitle = styled.h1`
  line-height: 1.5;
  max-width: 75%;
  text-align: center;
  margin-bottom: 30px;

  ${media.xl.min} {
    max-width: auto;
    font-size: 48px;
  }
`

const StyledActionButton = styled.button<StyledActionButtonProps>`
  display: ${({ noActionButton }) => (noActionButton ? 'none' : 'flex')};
  width: 100%;
  background: ${({ buttonColor }) => buttonColor};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 34px;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: 0.3s;
  border: ${({ buttonColor }) => `2px solid ${buttonColor}`};
  font-weight: 500;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }

  svg {
    position: absolute;
    right: 27px;

    path {
      transition: 0.3s;
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  ${media.md.min} {
    padding: 16px 69px;
    width: fit-content;
  }
`

const StyledDiscoverButton = styled.button<{
  noDiscoverButton?: boolean
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 60px;

  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 29px;
  height: 130px;
  width: 86px;
  font-size: 16px;
  margin-top: 33%;
  box-shadow: ${({ theme }) => theme.colors.lightorange} 0 57px 59px;
  cursor: pointer;

  img {
    margin-top: 15px;
  }

  ${media.xl.min} {
    font-size: 16px;

    img {
      width: 20px;
    }
  }

  ${media.md.max} {
    display: ${({ noDiscoverButton }) => (noDiscoverButton ? 'none' : 'flex')};
  }
`

const StyledCircles = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const RedCircle = styled.div`
  display: block;
  position: absolute;
  width: 140px;
  height: 140px;
  top: 55%;
  right: -70px;
  border: 1px solid #dd001a;
  border-radius: 50%;

  ${media.xl.min} {
    width: 210px;
    height: 210px;
    left: -10%;
    right: auto;
    top: 50%;
  }

  ${media.xxl.min} {
    width: 310px;
    height: 310px;
    left: -185px;
    top: 50%;
  }
`

const GreenCircle = styled.div`
  position: absolute;
  height: 200px;
  width: 200px;
  border: 1px solid ${({ theme }) => theme.colors.green};
  border-radius: 50%;
  top: 25%;
  left: -80px;

  ${media.xl.min} {
    width: 340px;
    height: 340px;
    left: 15%;
    top: auto;
    bottom: 15%;
  }

  ${media.xxl.min} {
    width: 440px;
    height: 440px;
    left: 350px;
    top: auto;
    bottom: 20%;
  }
`

const BlueCircle = styled.div`
  position: absolute;
  height: 420px;
  width: 420px;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  top: -100px;
  right: -273px;

  ${media.xl.min} {
    width: 700px;
    height: 700px;
    right: -50px;
    top: -50px;
  }

  ${media.xxl.min} {
    top: -100px;
    width: 1000px;
    height: 1000px;
    right: -50px;
  }
`

const OrangeCircle = styled.div`
  position: absolute;
  height: 360px;
  width: 360px;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  top: -80px;
  left: -175px;

  ${media.xl.min} {
    width: 590px;
    height: 590px;
    left: 100px;
    top: -125px;
  }

  ${media.xxl.min} {
    width: 790px;
    height: 790px;
    left: 100px;
    top: -125px;
  }
`

const MainHeader: React.FC<MainHeaderProps> = ({
  image,
  title,
  buttonText = 'Zacznij',
  buttonColor = 'orange',
  noDiscoverButton,
  noActionButton,
  hasPeopleImg,
  noRadialGradient,
}) => {
  return (
    <StyledWrapper id="main-header" hasPeopleImg={hasPeopleImg}>
      <StyledCircles>
        <RedCircle />
        <GreenCircle />
        <BlueCircle />
        <OrangeCircle />
      </StyledCircles>
      <StyledHeroImage
        image={image.src}
        alt={image.alt}
        noRadialGradient={noRadialGradient}
      />
      <StaticImage
        className="people-img"
        src="../../assets/images/hero-people.png"
        placeholder="tracedSVG"
        alt=""
        style={{
          position: 'absolute',
          left: '50vw',

          transform: 'translateX(-40%)',
          bottom: 0,
          zIndex: 0,
        }}
      />

      <StyledMainHeaderContent>
        <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
        <StyledActionButton
          noActionButton={noActionButton}
          onClick={() => scrollToNextSibling('#main-header')}
          buttonColor={buttonColor}
        >
          <p>{buttonText}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.779"
            height="14.779"
            viewBox="0 0 14.779 14.779"
          >
            <path
              id="Path_195"
              data-name="Path 195"
              d="M7.39,0,6.082,1.307l5.159,5.159H0V8.313H11.241L6.082,13.472,7.39,14.778l7.389-7.389Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </svg>
        </StyledActionButton>

        <StyledDiscoverButton
          onClick={() => scrollToNextSibling('#main-header')}
          noDiscoverButton={noDiscoverButton}
        >
          <p>Odkryj</p>
          <img src={discoverIcon} alt="discover icon" />
        </StyledDiscoverButton>
      </StyledMainHeaderContent>
    </StyledWrapper>
  )
}

export default MainHeader
